<template>
  <section>
    <land-img
      :height="wdHeight"
      :gradient="gradient"
      :src="cover"
      flat
      max-width="100%"
      tile
    >
      <v-container
        style="max-width: 1200px; height: 90%;"
      >
        <v-row
          class="ma-0 fill-height"
          align="center"
        >
          <v-col
            cols="12"
            md="7"
            sm="12"
          >
            <div class="co-flex-col ml-4">
              <span
                class="text-h6 text-md-h4"
                :class="[header.foreColor ? `${header.foreColor}--text` : 'white--text']"
              >
                {{ header.title }}
              </span>
              <span
                v-if="header.english"
                class="co-text-sm mt-4"
                :class="[header.foreColor ? `${header.foreColor}--text` : 'white--text']"
              >
                {{ header.english }}
              </span>
              <div class="co-w32 co-h4 co-bg-white mt-6" />
              <div
                class="co-w32"
                :class="[smAndDown ? 'co-h24' : 'co-h40']"
              />
              <span
                v-if="header.subtitle"
                :class="[
                  header.foreColor ? `${header.foreColor}--text` : 'white--text',
                  smAndDown ? 'co-text-sm' : 'co-text-md',
                ]"
              >{{ header.subtitle }}</span>
              <v-btn
                v-if="button.text"
                depressed
                :color="button.backColor || 'white'"
                :class="[button.foreColor ? `${button.foreColor}--text` : 'primary--text', 'mt-8']"
                width="120"
                @click="onMore"
              >
                {{ button.text }}
              </v-btn>
            </div>
          </v-col>
          <v-col
            cols="12"
            md="5"
            class="hidden-sm-and-down"
          />
        </v-row>
      </v-container>
    </land-img>
  </section>
</template>

<script>

  export default {
    name: 'SectionGuideContent',
    props: {
      header: {
        type: Object,
        default: () => {
          return {
            title: '标题名称',
            subtitle: '副标题名称'
          }
        }
      },
      cover: {
        type: String,
        default: ''
      },
      button: {
        type: Object,
        default: () => {
          return {
            text: '查看详情'
          }
        }
      },
    },
    data () {
      return {
        divisible: true,
        points: {
          xs: 280,
          sm: 360,
          md: 440,
          lg: 440,
          xl: 440
        },
      }
    },
    computed: {
      gradient () {
        const overlay1 = 'rgba(5, 11, 31, 0.60)'
        const overlay2 = 'rgba(5, 11, 31, 0.10)'
        return `to top right, ${overlay1}, ${overlay2}`
      },
      smAndDown () {
        return this.$vuetify.breakpoint.smAndDown
      },
      wdHeight () {
        const name = this.$vuetify.breakpoint.name
        const ht = this.points[name] || 500
        return ht
      },
      btnWidth () {
        if (this.$vuetify.breakpoint.smAndDown) {
          return 120
        } else {
          return 140
        }
      },
    },
    created () {
    },
    methods: {
      onMore () {
        this.$emit('view', {})
      },
    }
  }
</script>
